import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Route, SelectedTrip } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class EventsHubService {
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public defaultTrip$: BehaviorSubject<SelectedTrip | null> = new BehaviorSubject<SelectedTrip | null>(null);
  public httpUnauthorizedError$: Subject<boolean> = new Subject();

  constructor() {}

  public setLoggedIn(value: boolean): void {
    this.loggedIn$.next(value);
  }

  public setDefaultTrip(value: SelectedTrip): void {
    this.defaultTrip$.next(value);
  }

  public setUnauthorizedError(value: boolean): void {
    this.httpUnauthorizedError$.next(value);
  }
}
