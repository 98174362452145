import { Component, OnDestroy, OnInit } from '@angular/core';
import { MENU } from '@shared/menu';
import { MenuItem, User } from '@shared/models';
import { EventsHubService } from '@core/services/events-hub/events-hub.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'tig-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isLoggedIn: boolean = false;
  public MENU: MenuItem[] = MENU;
  public activeSubMenu: MenuItem[] | null = null;
  private subscriptions = new Subscription();
  public user: User | null = null;

  constructor(
    private router: Router,
    private readonly eventsHubService: EventsHubService,
    private readonly authService: AuthenticationService,
    private readonly offCanvasService: NgbOffcanvas
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.eventsHubService.loggedIn$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (this.isLoggedIn) {
          this.user = this.authService.getUserInfo();
        } else {
          this.user = null;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public logout(): void {
    this.authService.logout();
  }

  public openMenu(): void {
    const offcanvasRef = this.offCanvasService.open(MenuComponent);
  }
}
