import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Route } from './route.model';
import { Trip } from './trip.model';
import { DropOff } from './ticket.model';

export interface SelectedTrip {
  route: Route;
  date: NgbDateStruct;
  trip: Trip;
  dropOffs: DropOff[]
}
