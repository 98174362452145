<section class="menu">
  <div class="d-flex w-100">
    <div>
      <a class="d-inline-block p-2 max-w-100px" (click)="goTo('/')">
        <img src="/assets/images/tig-logo.png" alt="TIG" class="img-fluid" />
      </a>
    </div>
    <div class="px-4 py-2 ml-auto">
      <button class="btn btn-clear" (click)="closeMenu()"><fa-icon icon="times" [rotate]="90"></fa-icon></button>
    </div>
  </div>
  <nav>
    <ul class="list-unstyled p-3">
      <li class="d-flex">
        <span class="text-uppercase d-inline-block ml-2">
          {{ user.fullName }}
        </span>
      </li>
      <li>
        <button class="btn btn-clear" (click)="goTo('/sales/boardings')">
          <fa-icon icon="van-shuttle" class="mr-2"></fa-icon>
          <span>Abordajes</span>
        </button>
      </li>
      <!--<li>
        <button class="btn btn-clear" (click)="goTo('/sales/drop-offs')">
          <fa-icon icon="walking" class="mr-2"></fa-icon>
          <span>Destino Final</span>
        </button>
      </li>-->
      <li>
        <button class="btn btn-clear" (click)="goTo('/sales/alerts')">
          <fa-icon icon="bell" class="mr-2"></fa-icon>
          <span>Alertas</span>
        </button>
      </li>
      <li>
        <button class="btn btn-clear" (click)="goTo('/sales/checks')">
          <fa-icon icon="qrcode" class="mr-2"></fa-icon>
          <span>Leer Código QR</span>
        </button>
      </li>
      <li>
        <button class="btn btn-clear" (click)="goTo('/sales/tickets')">
          <fa-icon icon="check" class="mr-2"></fa-icon>
          <span>Vender Boletos</span>
        </button>
      </li>
      <li>
        <button class="btn btn-clear" (click)="goTo('/sales/driver-report')">
          <fa-icon icon="pen-to-square" class="mr-2"></fa-icon>
          <span>Resumen de Ventas</span>
        </button>
      </li>
      <li>
        <button class="btn btn-clear" (click)="logout()">
          <fa-icon icon="right-from-bracket" class="mr-2"></fa-icon>
          <span>Salir</span>
        </button>
      </li>
    </ul>
  </nav>
</section>
