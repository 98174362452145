<header>
  <div class="container-fluid">
    <div class="max-content-width">
      <div class="row d-flex justify-content-between">
        <div
          class="d-flex"
          [ngClass]="{
            'justify-content-start col-6 ': isLoggedIn,
            'col-12 justify-content-center': !isLoggedIn
          }"
        >
          <a class="d-inline-block p-2 max-w-100px" routerLink="/">
            <img
              src="/assets/images/tig-logo.png"
              alt="TIG"
              class="img-fluid"
            />
          </a>
        </div>

        <div
          class="col-6 text-end justify-content-end align-items-center"
          [ngClass]="{ 'd-flex': isLoggedIn, 'd-none': !isLoggedIn }"
        >
          <button class="btn btn-clear" (click)="openMenu()"><fa-icon icon="bars"></fa-icon></button>
          <!--<div ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-outline-primary"
              id="ProfileDropdown"
              ngbDropdownToggle
            >
              {{ user?.fullName }}
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem class="btn btn-clear" (click)="logout()">
                Salir
              </button>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid border-top" *ngIf="activeSubMenu">
    <div class="max-content-width">
      <div class="row">
        <div class="col">
          <ul class="list-unstyled mb-0 d-flex">
            <li *ngFor="let item of activeSubMenu" class="me-3">
              <a
                [routerLink]="item.link"
                routerLinkActive="active"
                [title]="item.name"
                class="d-inline-block p-2"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
