import { BaseEntity } from "@shared/models";

export class Boarding extends BaseEntity {
  public name: string;
  public description: string;
  public rate: number | null;

  constructor(obj: Partial<Boarding>) {
    super(obj);
    this.name = obj.name || "";
    this.description = obj.description || "";
    this.rate = obj.rate || null;
  }
}
