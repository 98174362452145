import { CONFIG } from '@config/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, throwError, timeout } from 'rxjs';
import { Credentials } from '@shared/models';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  private basePath: string = environment.apiPath;

  constructor(private readonly http: HttpClient) {}

  public login(credentials: Credentials): Observable<any> {
    const formData: HttpParams = new HttpParams()
      .set('grant_type', CONFIG.auth0.grantType)
      .set('username', credentials.email)
      .set('password', credentials.password)
      .set('client_id', CONFIG.auth0.clientId)
      .set('client_secret', CONFIG.auth0.clientSecret);

    return this.http.post(`${this.basePath}/oauth/token`, formData).pipe(
      timeout(CONFIG.httpTimeout),
      catchError(ex => throwError(() => ex))
    );
  }
}
