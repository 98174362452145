import { UserService } from '@core/data-services/user/user.service';
import { Injectable } from '@angular/core';
import { EventsHubService } from '../events-hub/events-hub.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Credentials, Token, AuthData, User, SelectedTrip } from '@shared/models';
import { PasswordService } from '../../data-services/password/password.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private authData: AuthData | null = null;

  constructor(
    private readonly passwordService: PasswordService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly storage: LocalStorageService,
    private readonly eventsHub: EventsHubService
  ) {
    const authData: AuthData | null = this.storage.getAuthData();

    if (authData) {
      this.setAuthData(authData);
      this.unAuthorizedListener();
    } else {
      this.logout();
    }
  }

  public isLoggedIn(): boolean {
    return !!this.eventsHub.loggedIn$.getValue();
  }

  public getUserInfo(): User {
    return this.authData?.user as User;
  }

  public getDefaultTrip(): SelectedTrip | null {
    return this.authData?.selectedTrip as SelectedTrip | null;
  }

  public async login(credentials: Credentials): Promise<any> {
    let loginResponse: Token;
    let user: User = new User({});
    try {
      loginResponse = await lastValueFrom(this.passwordService.login(credentials));

      if (loginResponse) {
        this.authData = { tokenInfo: loginResponse, user: null, selectedTrip: null };
        user = await lastValueFrom(this.userService.getRecord(loginResponse.user_id));

        if (user) {
          this.authData.user = user;
        }

        this.setAuthData(this.authData);
      }
    } catch (error) {
      this.eventsHub.setLoggedIn(false);
      throw error;
    }

    const authData: AuthData = { tokenInfo: loginResponse, user: user, selectedTrip: null };
    return authData; // logged in
  }

  public getToken(): string | null {
    let token: string | null = null;
    token = this.authData?.tokenInfo.access_token || null;
    return token;
  }

  public logout(): void {
    //this.modalService.dismissAll();

    this.cleanSession();
    this.router.navigate(['/']);
  }

  public setDefaultTrip(selectedTrip: SelectedTrip | null): void {
    const newAuthData: AuthData = {
      tokenInfo: this.authData?.tokenInfo as Token,
      user: this.authData?.user as User,
      selectedTrip: selectedTrip,
    };

    this.setAuthData(newAuthData);
  }

  private cleanSession(): void {
    this.authData = null;
    this.eventsHub.setLoggedIn(false);
    this.storage.deleteAuthData();
    this.storage.deleteAll();
  }

  private setAuthData(authData: AuthData): void {
    this.authData = authData;
    this.storage.setAuthData(this.authData);
    if (authData !== null) {
      this.eventsHub.setLoggedIn(true);
    }
  }

  private unAuthorizedListener(): void {
    this.eventsHub.httpUnauthorizedError$.subscribe(value => {
      if (value) {
        this.logout();
      }
    });
  }
}
