import { BaseEntity, Boarding } from '@shared/models';

export class Route extends BaseEntity {
  public name: string;
  public departure: string;
  public destiny: string;
  public boardings: Boarding[];

  constructor(obj: Partial<Route>) {
    super(obj);
    this.name = obj.name || '';
    this.departure = obj.departure || '';
    this.destiny = obj.destiny || '';
    this.boardings = obj.boardings || [];
  }
}
